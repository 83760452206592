<template>
  <div class="flex-end dark">
    <div data-v-26ee29d9="">
      <!---->
      <div data-v-26ee29d9="" class="header-container header">
        <div data-v-26ee29d9="" class="menu-scroll-frame">
          <div data-v-26ee29d9="" class="menu-notice menu-notice-orange">
            <h2>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGqADAAQAAAABAAAAGgAAAABMybYKAAACV0lEQVRIDa1WMU8UQRR+b8DOxALimUiQAhJoJYFQGEkUEivwPwhYgO0lQCkkR6sWF+2NFaGV5hISE020hRLlCiXQaSV3z+/NMnuz7szt4jnJZt9+73vf29mdeW+YCoZsj4yTXCyQ0DwxDZPILRvC/B3YN2Dvifv3eP34qJsUx5zyfGgKIjsQvh/jZHFu4LnKm81PWTx5yiWS+uQ1Ojt9QdJeCQUUYmzqNHhzjVc+//a5mUSyPT5A8nMXs7jnk65sMx8QX3/M60fnLtY4w87kfyRRQX1RaFnNywRpIjr78bJwJkwtYq4lF+xuQ5PpL7gc9tPZH0/y0YHRO/Mb3mguqV+2hl7jxZ5EuamDp3WBJDPS1VVuND2ab3twzqwpYqR2ZwJvVnIJ50RKADKre9FQq7VQgt0j5WLRUJvmelQpDkcOY8tKMbU3BkqXwf9JaldvUt2jkaOzj7pTndevJL7t/NE7Ph2qcPkx5VF924MDJnL021JPNBpw5yGRR7J1+4N1iMzkCREE7cSQof2IOwwLzeDlyidRFfQsQ319e2HFAMpIwfwKl9awdoARhtAYDVe/HiJlI8zIoe9Q61ZxPUPM25w3CHBDu69bddUgJwdypQOJZ3fQgGW10yWKalzHnloOELMQ0xfM5he4xc0R3ZY3Tp6qgJsR0WBlFd/+IKsaeBK6Wy4JtNDSnUKayPZ4tN9SyVx07O5auXduSBNpjO3xYzcekh4w/nXYw0nlgX9esNoxvaTrEpqWzMY4WfyKx61sMNJoY9Sepe0kdIDUDY+9mGyTv6M7z38Af0O7OzFnfV8AAAAASUVORK5CYII="
              />请链接到HECO Mainnet
            </h2>
            <!---->
            <!---->
            <!---->
            <!---->
            <!---->
            <!---->
            <!---->
          </div>
          <div data-v-26ee29d9="" class="menu-scroll">
            <div data-v-26ee29d9="" class="logos header-logos logo">
              <img data-v-26ee29d9="" src="/_nuxt/img/logo-4.5055f3b.png" />
            </div>
            <div data-v-26ee29d9="" class="menu-content">
              <div data-v-26ee29d9="" class="features">
                <div data-v-26ee29d9="" class="connect-wallet">
                  <div data-v-26ee29d9="" class="account-basic-info">
                    <div data-v-26ee29d9="" class="address-text pointer">
                      <img
                        data-v-26ee29d9=""
                        src="/jl/walletSuccess.abb8324e.png"
                        alt=""
                      /><span data-v-26ee29d9="" class="addr-span">...</span>
                    </div>
                    <!---->
                  </div>
                  <div data-v-26ee29d9="" class="lhb">
                    <div class="amount">钱包余额：0.0000 LHB</div>
                    <br />
                    <div class="amount">待取收益：0.0000 LHB</div>
                    <br />
                    <div class="withdraw">提取收益挖矿</div>
                    <div class="el-dialog__wrapper" style="display: none">
                      <div
                        role="dialog"
                        aria-modal="true"
                        aria-label="LHB"
                        class="el-dialog ant-modal dialog-standard-lhb dark"
                        style="margin-top: 15vh; width: 400px"
                      >
                        <div class="el-dialog__header">
                          <div class="el-dialog__header-slot">
                            <img src="/coins/lhb.png" />
                            LHB
                          </div>
                          <button
                            type="button"
                            aria-label="Close"
                            class="el-dialog__headerbtn"
                          >
                            <i
                              class="el-dialog__close el-icon el-icon-close"
                            ></i>
                          </button>
                        </div>
                        <!----><!---->
                      </div>
                    </div>
                  </div>
                </div>
                <div data-v-26ee29d9="" class="horizontal-line menu"></div>
                <!---->
                <!----><!----><!---->
              </div>
              <div data-v-26ee29d9="" class="menu-links">
                <ul data-v-26ee29d9="">
                  <li data-v-26ee29d9="">
                    <a data-v-26ee29d9="" class="active"
                      ><i
                        data-v-26ee29d9=""
                        class="icon-logo icon-logo-market"
                      ></i
                      >借贷市场</a
                    >
                  </li>
                  <li data-v-26ee29d9="">
                    <a data-v-26ee29d9="" class=""
                      ><i
                        data-v-26ee29d9=""
                        class="icon-logo icon-logo-mining"
                      ></i
                      >质押挖矿</a
                    >
                  </li>
                  <li data-v-26ee29d9="">
                    <a data-v-26ee29d9="" class=""
                      ><i
                        data-v-26ee29d9=""
                        class="icon-logo icon-logo-governance"
                      ></i
                      >DAO</a
                    >
                  </li>
                  <li data-v-26ee29d9="">
                    <a data-v-26ee29d9="" class=""
                      ><i data-v-26ee29d9="" class="icon-logo icon-logo-dao"></i
                      >投票治理</a
                    >
                  </li>
                </ul>
              </div>
              <div data-v-26ee29d9="" class="horizontal-line menu"></div>
              <div data-v-26ee29d9="" class="links new-links">
                <a
                  data-v-26ee29d9=""
                  href="https://www.yuque.com/lendhub/kb"
                  target="report"
                  class="logos small-logo shuoming-logo"
                  ><span data-v-26ee29d9=""></span
                  ><span data-v-26ee29d9="">产品说明</span></a
                >
                <a
                  data-v-26ee29d9=""
                  href="https://www.yuque.com/lendhub/thcoc4"
                  target="report"
                  class="logos small-logo shuoming-logo"
                  style="display: none"
                  ><span data-v-26ee29d9=""></span
                  ><span data-v-26ee29d9="">产品说明</span></a
                >
                <a
                  data-v-26ee29d9=""
                  href="https://www.yuque.com/lendhub/kb/gzvbof"
                  target="report"
                  class="logos small-logo mining-logo"
                  ><span data-v-26ee29d9=""></span
                  ><span data-v-26ee29d9="">挖矿教程</span></a
                >
                <a
                  data-v-26ee29d9=""
                  href="javascript:;"
                  class="logos small-logo theme-logo_dark"
                  style="display: none"
                  ><span data-v-26ee29d9=""></span
                  ><span data-v-26ee29d9="">深色模式</span></a
                >
                <a
                  data-v-26ee29d9=""
                  href="javascript:;"
                  class="logos small-logo theme-logo_light"
                  ><span data-v-26ee29d9=""></span
                  ><span data-v-26ee29d9="">浅色模式</span></a
                >
                <a
                  data-v-26ee29d9=""
                  href="javascript:;"
                  class="logos small-logo lang-logo_lang"
                  ><span data-v-26ee29d9=""></span
                  ><span data-v-26ee29d9="">语言切换</span>
                  <i
                    data-v-26ee29d9=""
                    class="el-icon-caret-bottom el-icon-lang"
                  ></i>
                  <i
                    data-v-26ee29d9=""
                    class="el-icon-caret-top el-icon-lang"
                    style="display: none"
                  ></i
                ></a>
                <div
                  data-v-26ee29d9=""
                  class="aside-lang-list"
                  style="display: none"
                >
                  <a data-v-26ee29d9="" href="javascript:;">中文简体</a>
                  <a data-v-26ee29d9="" href="javascript:;">English</a>
                  <a data-v-26ee29d9="" href="javascript:;">日本語</a>
                </div>
              </div>
              <div data-v-26ee29d9="" class="links"><!----></div>
            </div>
          </div>
        </div>
        <div data-v-26ee29d9="" class="menu-footer menu-footer-mini">
          <a
            data-v-26ee29d9=""
            href="https://twitter.com/LendHubDefi"
            target="twitter"
            class="logos mini-logo twitter-logo"
            ><span data-v-26ee29d9=""></span
            ><span data-v-26ee29d9="">Twitter</span></a
          >
          <a
            data-v-26ee29d9=""
            href="https://t.me/Lendhub"
            target="telegram"
            class="logos mini-logo telegram-logo"
            ><span data-v-26ee29d9=""></span
            ><span data-v-26ee29d9="">Telegram</span></a
          ><a
            data-v-26ee29d9=""
            href="https://github.com/lendhub/lendhub "
            target="github"
            class="logos mini-logo github-logo"
            ><span data-v-26ee29d9=""></span
            ><span data-v-26ee29d9="">Github</span></a
          >
          <a
            data-v-26ee29d9=""
            href="javascript:;"
            class="logos mini-logo wechat-logo"
            ><span data-v-26ee29d9=""></span
            ><span data-v-26ee29d9="">Wechat</span></a
          >
          <div
            data-v-26ee29d9=""
            class="pop-weixin pop-weixin-mini"
            style="display: none"
          >
            <img data-v-26ee29d9="" src="/_nuxt/img/wechat.4229b99.jpeg" />
          </div>
          <!---->
        </div>
        <div
          data-v-26ee29d9=""
          class="el-dialog__wrapper"
          style="display: none"
        >
          <div
            role="dialog"
            aria-modal="true"
            aria-label="Connect Wallet"
            class="el-dialog login-modal custom-modal"
            style="margin-top: 15vh; width: 30%"
          >
            <div class="el-dialog__header">
              <span class="el-dialog__title">Connect Wallet</span
              ><button
                type="button"
                aria-label="Close"
                class="el-dialog__headerbtn"
              >
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </button>
            </div>
            <!----><!---->
          </div>
        </div>
      </div>
      <!---->
    </div>
    <div class="main right-container">
      <div class="main-content">
        <div data-v-deaf73aa="" class="index">
          <!---->
          <div data-v-deaf73aa="" class="nav-wallet-banner">
            <div data-v-deaf73aa="" class="nav-wallet-container">
              <ul data-v-deaf73aa="" class="nav-wallet-content">
                <li data-v-deaf73aa="">
                  <h2 data-v-deaf73aa="">
                    <i
                      data-v-deaf73aa=""
                      class="icon-wallet icon-wallet-total"
                    ></i
                    >存借总量：
                  </h2>
                  <p data-v-deaf73aa="">$0</p>
                </li>
                <li data-v-deaf73aa="">
                  <h2 data-v-deaf73aa="">
                    <i
                      data-v-deaf73aa=""
                      class="icon-wallet icon-wallet-income"
                    ></i
                    >TVL：
                  </h2>
                  <p data-v-deaf73aa="">$0</p>
                </li>
                <li data-v-deaf73aa="">
                  <h2 data-v-deaf73aa="">
                    <i
                      data-v-deaf73aa=""
                      class="icon-wallet icon-wallet-price"
                    ></i
                    >LHB当前价格：
                  </h2>
                  <p data-v-deaf73aa="">$0</p>
                </li>
                <li data-v-deaf73aa="">
                  <h2 data-v-deaf73aa="">
                    <i
                      data-v-deaf73aa=""
                      class="icon-wallet icon-wallet-tongji"
                    ></i
                    >LHB流通量：
                  </h2>
                  <p data-v-deaf73aa="">-</p>
                </li>
              </ul>
            </div>
          </div>
          <!---->
          <div data-v-deaf73aa="" class="userTip-container">
            <div data-v-deaf73aa="" class="content">
              <h2 data-v-deaf73aa="">
                <img
                  data-v-deaf73aa=""
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAYAAADWm14/AAAAAXNSR0IArs4c6QAAAmpJREFUSA21VktrFEEQ/mp181CiWbyseZggIR4kSEBy8Ogx/gVR9KQgvg6KhngyG5OAOXjwIIJecxHBf+DJo3jSeFBRUTxsRFYhE6as6Qk9j+7pmU7Yhobq6urqb7a/r2oJOxy8NDqELX4px6dA9Bh3vtwkIvZNV/M9oOMDfgLmkzJ7EYbX0TpyXu95GOQRq0N5YfgMGK+0Qxn0A/X9k3T7/Z+s373y/gV47XiPpHxopuUmgs490+/2eAPA+u9r8vWTBWmvcuto0Z71iBcAXh5vAjyvM/UPAo1xvZS9HoSbqylHqekFAJvBA/n6AZV1bx9w6TVwWebQdOoinuXWyGzK4TQrA+D7IzMgnNPZGmPAvgZEggLghHYrg3l1mytZv2VVCQAzk1z+SL6+mmoijkRcqTAqAVAaZ56pkC8VwvMxZ1Iui1kKgJeODUixWbScdbsirkScKRmlALDVEdaLxncyhDOKO46zTgBK04xKb2m9I+KMcEdxyBoAOAHEmhZt72ZE3HH0iUIAsZa5WM/tz8DftryONMDvb90QhUOKS5Yoq6yUhtc33jlKbpwqqoR9MtufLKlzLqIVmvt6K+cteAJ3vU9y/Nuodnl0Qrhk6xPGExj1Prlul5a9TxgAMvW+7Mqza8CVN8CB4bLI7X2zT2QAGPXelfbQBDB2Cjgol0+cdkVm93J9QgPwrve1PUnitJ147VauT2gA3vXeR4YGlKRPKBkqjQadD94l10eGeRCEZzT37UIMYGH0Ijh8mo/p8jpErb8ZPwGF9S5fZqYnqQwyYgCH68/ln80LmYEZ2QUP4af8lbpBdz/++g8+Js+XhjTSYAAAAABJRU5ErkJggg=="
                  class="icon-tips"
                />温馨提示：出于安全考虑，MDX的抵押率为零，不可用于抵押。BAGS不可用于存借，即将下线。
              </h2>
            </div>
          </div>
          <div data-v-deaf73aa="" class="userInfo-container">
            <div data-v-deaf73aa="" class="title">账户状态</div>
            <div data-v-deaf73aa="" class="userInfo-content">
              <div data-v-deaf73aa="" class="wallet-balance">
                <dl data-v-deaf73aa="">
                  <dd data-v-deaf73aa="">
                    存款金额：<span data-v-deaf73aa=""> $0 </span>
                  </dd>
                  <dd data-v-deaf73aa="">
                    借款金额：<span data-v-deaf73aa=""> $0 </span>
                  </dd>
                </dl>
                <!---->
              </div>
              <div data-v-deaf73aa="" class="wallet-risk">
                <div data-v-deaf73aa="" class="wallet-risk-filed">
                  借款额度使用率:
                </div>
                <div data-v-deaf73aa="" class="wallet-risk-progress">
                  <div data-v-deaf73aa="" class="new-custom-progress">
                    <div data-v-deaf73aa="" class="new-custom-progress-bar">
                      <div
                        data-v-deaf73aa=""
                        class="new-custom-progress__outer"
                      >
                        <div
                          data-v-deaf73aa=""
                          class="new-custom-progress__inner new-custom-progress__inner-lv1"
                          style="width: 0%"
                        ></div>
                      </div>
                      <div
                        data-v-deaf73aa=""
                        class="new-custom-progress__text new-custom-progress__text-lv1"
                        style="background-color: rgb(235, 238, 245); left: 0%"
                      >
                        0%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-v-deaf73aa="" class="nav-container">
            <div class="userList-container">
              <div class="ant-tabs ant-tabs-top">
                <div role="tablist" class="ant-tabs-nav">
                  <div class="ant-tabs-nav-wrap ant-tabs-nav-wrap-userTab">
                    <div class="ant-tabs-nav-list">
                      <div class="ant-tabs-tab ant-tabs-tab-active">
                        <div
                          id="rc-tabs-0-tab-1"
                          role="tab"
                          tabindex="0"
                          class="ant-tabs-tab-btn"
                        >
                          我的存款
                        </div>
                      </div>
                      <div class="ant-tabs-tab">
                        <div
                          id="rc-tabs-0-tab-2"
                          role="tab"
                          class="ant-tabs-tab-btn"
                        >
                          我的借款
                        </div>
                      </div>
                      <div
                        class="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                        style="left: 0px; width: 56px"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="ant-tabs-nav-operations ant-tabs-nav-operations-hidden"
                  >
                    <button
                      id="rc-tabs-0-more"
                      type="button"
                      tabindex="-1"
                      aria-hidden="true"
                      aria-haspopup="listbox"
                      aria-controls="rc-tabs-0-more-popup"
                      aria-expanded="false"
                      class="ant-tabs-nav-more"
                      style="visibility: hidden; order: 1"
                    >
                      <span
                        role="img"
                        aria-label="ellipsis"
                        class="anticon anticon-ellipsis"
                        ><svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="ellipsis"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"
                          ></path></svg
                      ></span>
                    </button>
                  </div>
                </div>
                <div class="ant-tabs-content-holder">
                  <div class="ant-tabs-content ant-tabs-content-top">
                    <div
                      id="rc-tabs-0-panel-1"
                      role="tabpanel"
                      tabindex="0"
                      aria-hidden="false"
                      aria-labelledby="rc-tabs-0-tab-1"
                      class="ant-tabs-tabpane ant-tabs-tabpane-active"
                    >
                      <div class="userTable">
                        <div class="warning-tip">
                          <div>
                            <span class="icon">!</span
                            >重要提示：存款所得到的lToken（lHT、lUSDT等）为重要的存款凭证，请不要轻易转移给其他人！<!---->
                          </div>
                          <img src="/jl/closeBlack.a968c3b4.svg" />
                        </div>
                        <div class="ant-table-wrapper">
                          <div class="ant-spin-nested-loading">
                            <div class="ant-spin-container">
                              <div class="ant-table">
                                <div class="ant-table-container">
                                  <div class="ant-table-content">
                                    <table style="table-layout: auto">
                                      <thead class="ant-table-thead">
                                        <tr>
                                          <th class="ant-table-cell">资产</th>
                                          <th class="ant-table-cell">
                                            存款APY<span
                                              class="ant-table-cell"
                                            ></span>
                                          </th>
                                          <th class="ant-table-cell">
                                            挖矿APY<span
                                              class="ant-table-cell"
                                            ></span>
                                          </th>
                                          <th class="ant-table-cell">已存</th>
                                          <th class="ant-table-cell">
                                            用作抵押
                                          </th>
                                          <th class="ant-table-cell">操作</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td colspan="6">
                                            <p class="t-no-data"><i></i></p>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <!---->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="rc-tabs-0-panel-2"
                      role="tabpanel"
                      tabindex="-1"
                      aria-hidden="true"
                      aria-labelledby="rc-tabs-0-tab-2"
                      class="ant-tabs-tabpane"
                      style="display: none"
                    ></div>
                  </div>
                  <!---->
                </div>
              </div>
            </div>
            <div class="el-dialog__wrapper" style="display: none">
              <div
                role="dialog"
                aria-modal="true"
                aria-label="开启抵押"
                class="el-dialog ant-modal mortgage-modal"
                style="margin-top: 15vh; width: 30%"
              >
                <div class="el-dialog__header">
                  <span class="el-dialog__title">开启抵押</span
                  ><button
                    type="button"
                    aria-label="Close"
                    class="el-dialog__headerbtn"
                  >
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
                </div>
                <!----><!---->
              </div>
            </div>
            <div class="el-dialog__wrapper" style="display: none">
              <div
                role="dialog"
                aria-modal="true"
                aria-label="取消抵押"
                class="el-dialog ant-modal mortgage-modal"
                style="margin-top: 15vh; width: 30%"
              >
                <div class="el-dialog__header">
                  <span class="el-dialog__title">取消抵押</span
                  ><button
                    type="button"
                    aria-label="Close"
                    class="el-dialog__headerbtn"
                  >
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
                </div>
                <!----><!---->
              </div>
            </div>
          </div>
          <div data-v-deaf73aa="" class="infoList-container">
            <div data-v-deaf73aa="" class="title">单币市场</div>
            <div data-v-deaf73aa="" class="ant-table-wrapper">
              <div data-v-deaf73aa="" class="ant-spin-nested-loading">
                <div data-v-deaf73aa="" class="ant-spin-container">
                  <div
                    data-v-deaf73aa=""
                    class="ant-table ant-table-fixed-column ant-table-scroll-horizontal ant-table-has-fix-left ant-table-has-fix-right"
                  >
                    <div data-v-deaf73aa="" class="ant-table-container">
                      <div
                        data-v-deaf73aa=""
                        class="ant-table-content"
                        style="overflow: auto hidden"
                      >
                        <table data-v-deaf73aa="">
                          <thead data-v-deaf73aa="" class="ant-table-thead">
                            <tr data-v-deaf73aa="">
                              <th
                                data-v-deaf73aa=""
                                title="Asset"
                                class="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last ant-table-cell-ellipsis"
                                style="
                                  position: sticky;
                                  left: 0px;
                                  z-index: 100;
                                "
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                  >资产</span
                                >
                              </th>
                              <th data-v-deaf73aa="" class="ant-table-cell">
                                存款APY
                                <i
                                  data-v-deaf73aa=""
                                  class="el-tooltip el-icon-question"
                                  aria-describedby="el-tooltip-7291"
                                  tabindex="0"
                                ></i>
                              </th>
                              <th data-v-deaf73aa="" class="ant-table-cell">
                                借款成本
                                <i
                                  data-v-deaf73aa=""
                                  class="el-tooltip el-icon-question"
                                  aria-describedby="el-tooltip-8527"
                                  tabindex="0"
                                ></i>
                              </th>
                              <th
                                data-v-deaf73aa=""
                                class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                >
                                  存借总额
                                </span>
                              </th>
                              <th
                                data-v-deaf73aa=""
                                class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                  >剩余可借
                                </span>
                              </th>
                              <th
                                data-v-deaf73aa=""
                                class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                  >我的钱包余额</span
                                >
                              </th>
                              <!---->
                              <th
                                data-v-deaf73aa=""
                                title="Operation"
                                class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                  >操作</span
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody data-v-deaf73aa="" class="ant-table-tbody">
                            <tr
                              data-v-deaf73aa=""
                              class="el-loading-parent--relative"
                            >
                              <td
                                data-v-deaf73aa=""
                                colspan="8"
                                style="text-align: center"
                              >
                                加载中...
                              </td>
                              <div class="el-loading-mask" style="">
                                <div class="el-loading-spinner">
                                  <svg viewBox="25 25 50 50" class="circular">
                                    <circle
                                      cx="50"
                                      cy="50"
                                      r="20"
                                      fill="none"
                                      class="path"
                                    ></circle></svg
                                  ><!---->
                                </div>
                              </div>
                            </tr>
                          </tbody>
                        </table>
                        <!---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-v-deaf73aa="" class="infoList-container">
            <div data-v-deaf73aa="" class="title">
              LP市场
              <span
                data-v-deaf73aa=""
                style="color: rgb(254, 175, 13); font-size: 10px"
              >
                存入LP会每10分钟进行自动复投
              </span>
            </div>
            <div data-v-deaf73aa="" class="ant-table-wrapper">
              <div data-v-deaf73aa="" class="ant-spin-nested-loading">
                <div data-v-deaf73aa="" class="ant-spin-container">
                  <div
                    data-v-deaf73aa=""
                    class="ant-table ant-table-fixed-column ant-table-scroll-horizontal ant-table-has-fix-left ant-table-has-fix-right"
                  >
                    <div data-v-deaf73aa="" class="ant-table-container">
                      <div
                        data-v-deaf73aa=""
                        class="ant-table-content"
                        style="overflow: auto hidden"
                      >
                        <table data-v-deaf73aa="">
                          <thead data-v-deaf73aa="" class="ant-table-thead">
                            <tr data-v-deaf73aa="">
                              <th
                                data-v-deaf73aa=""
                                title="Asset"
                                class="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last ant-table-cell-ellipsis"
                                style="
                                  position: sticky;
                                  left: 0px;
                                  z-index: 100;
                                "
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                  >资产</span
                                >
                              </th>
                              <th data-v-deaf73aa="" class="ant-table-cell">
                                存款APY
                                <i
                                  data-v-deaf73aa=""
                                  class="el-tooltip el-icon-question"
                                  aria-describedby="el-tooltip-1586"
                                  tabindex="0"
                                ></i>
                              </th>
                              <th
                                data-v-deaf73aa=""
                                class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                >
                                  存款总额
                                </span>
                              </th>
                              <th
                                data-v-deaf73aa=""
                                class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                  >我的钱包余额</span
                                >
                              </th>
                              <!---->
                              <th
                                data-v-deaf73aa=""
                                title="Operation"
                                class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                              >
                                <span
                                  data-v-deaf73aa=""
                                  class="ant-table-cell-content"
                                  >操作</span
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody data-v-deaf73aa="" class="ant-table-tbody">
                            <tr
                              data-v-deaf73aa=""
                              class="el-loading-parent--relative"
                            >
                              <td
                                data-v-deaf73aa=""
                                colspan="8"
                                style="text-align: center"
                              >
                                加载中...
                              </td>
                              <div class="el-loading-mask" style="">
                                <div class="el-loading-spinner">
                                  <svg viewBox="25 25 50 50" class="circular">
                                    <circle
                                      cx="50"
                                      cy="50"
                                      r="20"
                                      fill="none"
                                      class="path"
                                    ></circle></svg
                                  ><!---->
                                </div>
                              </div>
                            </tr>
                          </tbody>
                        </table>
                        <!---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-v-deaf73aa=""
            class="el-dialog__wrapper"
            style="display: none"
          >
            <div
              role="dialog"
              aria-modal="true"
              aria-label="dialog"
              class="el-dialog ant-modal"
              style="margin-top: 0px; width: 600px"
            >
              <div class="el-dialog__header">
                <div data-v-deaf73aa="" class="el-dialog__header-slot">
                  <img data-v-deaf73aa="" />
                </div>
                <button
                  type="button"
                  aria-label="Close"
                  class="el-dialog__headerbtn"
                >
                  <i class="el-dialog__close el-icon el-icon-close"></i>
                </button>
              </div>
              <!----><!---->
            </div>
          </div>
          <div
            data-v-deaf73aa=""
            class="el-dialog__wrapper"
            style="display: none"
          >
            <div
              role="dialog"
              aria-modal="true"
              aria-label="dialog"
              class="el-dialog borrow-modal ant-modal"
              style="margin-top: 0px; width: 600px"
            >
              <div class="el-dialog__header">
                <div data-v-deaf73aa="" class="el-dialog__header-slot">
                  <img data-v-deaf73aa="" />
                </div>
                <button
                  type="button"
                  aria-label="Close"
                  class="el-dialog__headerbtn"
                >
                  <i class="el-dialog__close el-icon el-icon-close"></i>
                </button>
              </div>
              <!----><!---->
            </div>
          </div>
        </div>
      </div>
      <div class="footer-container">
        <div class="footer-link">
          审计机构:
          <a
            href="https://www.lendhub.org/report/LendHubreport-020821.pdf"
            target="FairProof"
            class="link-logo"
            ><img src="/_nuxt/img/fairproof.7226619.png" alt="FairProof"
          /></a>
          <a
            href="https://www.lendhub.org/report/LendHubAuditReport.pdf"
            target="零时科技"
            class="link-logo"
            ><img src="/_nuxt/img/lingshi-logo@2x.e86fc7d.png" alt="零时科技"
          /></a>
          <a
            href="https://www.lendhub.org/report/LendHubAuditReport2.pdf"
            target="知道创宇"
            class="link-logo"
            ><img src="/_nuxt/img/zhidao-logo@2x.bfbb9d1.png" alt="知道创宇"
          /></a>
        </div>
        <p>©2021 lendhub.org</p>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../api/public";
export default {
  data() {
    return {
      param: {
        username: "18512312300",
        password: "111111",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  methods: {
    submitForm() {
      let _this = this;
      this.$refs.login.validate((valid) => {
        if (valid) {
          let params = {
            user_name: this.param.username,
            user_pwd: this.param.password,
            ip_addr: localStorage.getItem("ip"),
            region: "", //  所在地区
            browser: "", //   浏览器
            device: "", // 设备
            operating_system: "", // 操作系统
          };
          login(params).then((res) => {
            console.log(res);
            this.$message.success("登录成功");
            localStorage.setItem("user_info", JSON.stringify(res.data));
            _this.$router.push("/");
            console.log(_this.$router);
          });

          // this.$router.push("/");
        }
      });
    },
  },
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;

  overflow: hidden;
}
.ms-login .box {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
}
.ms-content {
  padding: 30px 30px;
}
.ms-login .tabs {
  padding-top: 10px;
  text-align: center;
}
.ms-login .tabs p {
  cursor: pointer;
  color: #999;
  font-size: 14px;
}
.ms-login .tabs p span {
  color: #c5963b;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}

.free-header {
  height: 82px;
}

.free-header .div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}

.free-header .div i {
  cursor: pointer;
  line-height: 82px;
}

.free-header .div img {
  vertical-align: middle;
  max-height: 82px;
}

.free-header .ul {
  padding: 30px 0;
}

.free-header .ul li {
  border: 1px dashed #999;
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  color: #999;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  /* float:left; */
}

.free-header .ul .active,
.free-header .ul li:hover {
  border-color: #c5963b;
  color: #c5963b;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  line-height: 54px;
  text-align: center;
  color: #333;
  height: 54px;
}
</style>